<script>
import Layout from "../../layouts/auth";
import {
  notificationMethods, SessionLoginState
} from "@/state/helpers";
import { mapState } from "vuex";

import VueCryptojs    from 'vue-cryptojs';
Vue.use(VueCryptojs);

import appConfig from "@/app.config";
import { required, minLength } from "vuelidate/lib/validators";
import Vue from "vue";
import store from "@/state/store";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSignIn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faSignIn);

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      badAttempts: 0,
      identifier: "",
      password: "",
      submitted: false,
      login_gateway_url: process.env.VUE_APP_ROOT_API + 'v1/auth-gateway/steam'
    };
  },
  validations: {
    identifier: { required, minLength: minLength(3) },
    password: { required, minLength: minLength(8) }
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
	created() {
		this.integrityCheck();
	},
  methods: {
    ...notificationMethods,
    handle_fail_and_reset() {
      this.submitted = false;
      this.password = "";
      this.badAttempts += 1;
      if(this.badAttempts <= 2) {
        this.$swal({
          icon: 'error',
          title: this.$t('error.server.bad_password.title'),
          text: this.$t('error.server.bad_password.message_generic')
        });
      } else {
        this.$swal({
          icon: 'error',
          title: this.$t('error.server.bad_password.forgotten.title'),
          text: this.$t('error.server.bad_password.forgotten.message')
        });
      }
    },
    handle_rate_limit_error() {
      this.submitted = false;
      this.$swal( {
        icon: 'warning',
        title: this.$t('error.server.ratelimit.title'),
        text: this.$t('error.server.ratelimit.message')
      });
    },
    handle_success(state) {
      if (store.state.login === SessionLoginState.LOGGED_IN) {
        this.$router.push({name: "index"}).catch((error) => {});
      } else if(store.state.login === SessionLoginState.VERIFY_2FA) {
        this.$router.push({name: "verify_2fa"}).catch((error) => {});
      } else if(store.state.login === SessionLoginState.VERIFY_LEGAL) {
        this.$router.push({name: "setup_legal"}).catch((error) => {});
      } else { // Invalid state
        location.reload();
      }
    },
    try_submit() {
      if(this.submitted) return;
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid) {
        this.submitted = false;
      } else {
        // Plain text password never leaves the client
        const hashed_password = this.CryptoJS.SHA256(this.password).toString(this.CryptoJS.enc.Hex);
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {

          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token,
            password: hashed_password,
            identifier: this.identifier,
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/native-login', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
          .then(response => {
            return response.json();
          })
          .then(data => {
            if(!data.status) {
              if(data.error === 'invalid-state') {
                /* Local state got desynchronized, reevaluating */
                console.log('Resolving state desynchronization');
                this.$router.push({name: "login"}).catch((error) => {});
              } else if(data.error === 'rate-limited') this.handle_rate_limit_error();
              else this.handle_fail_and_reset();
            }
            else {
              store.commit('login', data.state);
              this.handle_success(data.state);
            }
          })
          .catch(error => {
            console.log(`[ERROR] ${error}`);
            this.handle_fail_and_reset();
          });
          /* --- */
        })
        .catch(error => {
          console.log(error);
          this.handle_fail_and_reset();
        });
      }
    },
	  async integrityCheck() {
			let monocle = null;
			if(this.$refs.monocle) monocle = this.$refs.monocle.querySelector(`input[name="monocle"]`);
			if(!monocle) {
				setTimeout(() => {this.integrityCheck();}, 100);
				return;
			}

		  try {
			  let payload = {
				  payload: monocle.value
			  };
			  let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/verify`, {
				  method: 'POST',
				  body: JSON.stringify(payload),
				  credentials: 'include'
			  });
			  if (response.ok) {
				  // Pass
			  } else {
				  // Silent error
				  // throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
			  }
		  } catch (error) {
				// Silent error
			  // console.log(`[ERROR] ${error}`);
		  }
	  }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('login.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="@/assets/images/logo.svg" alt height="34" />
                </span>
              </div>
            </div>
	          <form ref="monocle" id="monocle-enriched" class="monocle-enriched d-none" @submit.prevent>
		          <button type="submit" disabled="disabled" class="d-none">_</button>
	          </form>
            <div class="mt-2 text-center">
              <a :href="login_gateway_url">
                <img src="https://cdn.cftools.de/olymp/images/login/steam.png">
              </a>
              <p><small>{{$t('login.steam.warning')}}</small></p>
            </div>
            <hr class="hr-text" :data-content="$t('login.or')">
            <div
              v-if="notification.message"
              :class="'alert ' + notification.type"
            >{{notification.message}}</div>

            <b-form class="p-2" @submit.prevent="try_submit">
              <b-form-group id="input-group-username-mail" :label="$t('login.identifier.prompt')" label-for="input-group-username-mail">
                <b-form-input
                  id="input-group-username-mail"
                  v-model.trim="identifier"
                  type="text"
                  :class="{ 'is-invalid': $v.identifier.$error }"
                  @change="$v.identifier.$touch()"
                ></b-form-input>
                <div v-if="$v.identifier.$error" class="invalid-feedback">
                  <span v-if="!$v.identifier.required">{{$t('login.identifier.warnings.required')}}</span>
                  <span v-if="!$v.identifier.minLength">{{$t('login.identifier.warnings.required')}}</span>
                </div>
              </b-form-group>

              <b-form-group id="input-group-2" :label="$t('login.password.prompt')" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  :class="{ 'is-invalid': $v.password.$error }"
                  @change="$v.password.$touch()"
                ></b-form-input>
                <div v-if="$v.password.$error" class="invalid-feedback">
                  <span v-if="!$v.password.required">{{$t('login.password.warnings.required')}}</span>
                  <span v-if="!$v.password.minLength">{{$t('login.password.warnings.required')}}</span>
                </div>
              </b-form-group>
              <div class="mt-3">
                <b-button type="submit" variant="primary" class="btn-block">
                  <font-awesome-icon icon="faSignIn"/>
                  {{$t('login.button_text')}}
                </b-button>
              </div>
              <div class="mt-4 text-center">
                <h5>
                <router-link tag="a" :to="{name: 'register'}">
                   {{$t('login.register')}}
                </router-link>
                </h5>
              </div>
            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-3 text-center">
          <small>
            © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
            <small style="font-size: 10px;">
              <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
              /
              <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
            </small>
          </small>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
